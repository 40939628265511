import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { getParkWebBannerText, isBannerEnabled } from "../../banner/Banner";
import TopBanner from "../../banner/TopBanner";
import { AdContent } from "../AdContent";
import { FooterParkWeb } from "../../adsense/footer/FooterParkWeb";
import LayoutStyle from "./LayoutStyle";
import GetDomainButton from "../commonComponents/GetDomainButton";
import classes from "./landers.module.scss";
import { TrustArcConsentDialog } from "../../consentManager/TrustArcComponents";
import classNames from "classnames";
import { useConsentStatus } from "../../consentManager/useConsentStatus";
import {
  LanderConfigAction,
  useLanderConfig,
} from "../../commons/ConfigContext";

export default function ParkWebLayout({ page, content }) {
  const { landerConfig, landerConfigDispatch } = useLanderConfig();

  const activeExperiment = [
    "parking_parkweb_boxed_related_searches",
    "parking_parkweb_ab_05",
  ].includes(landerConfig.experiment?.key);
  const inTreatmentCohort = landerConfig?.experiment?.cohort === "pw_treatment";

  const boxedRelatedSearches =
    landerConfig.experiment?.key === "parking_parkweb_boxed_related_searches" &&
    inTreatmentCohort;

  useEffect(() => {
    if (activeExperiment) {
      landerConfigDispatch({
        type: LanderConfigAction.UPDATE_BANNER_AND_CHANNEL_BY_TREATMENT,
        payload: inTreatmentCohort,
      });
    }
  }, [activeExperiment, inTreatmentCohort, landerConfigDispatch]);

  const consentStatus = useConsentStatus();

  let domainContent = "";

  if (isBannerEnabled(landerConfig)) {
    domainContent = (
      <div
        className={ classNames({
          [classes.paddingBottom]: boxedRelatedSearches,
        }) }
      >
        <Domain landerConfig={ landerConfig } />
        <DomainInfo landerConfig={ landerConfig } content={ content } />
        <GetDomainButton landerConfig={ landerConfig } />
      </div>
    );
  }

  let adContent = null;
  if (consentStatus.showConsentDialog) {
    adContent = <TrustArcConsentDialog />;
  } else {
    adContent = (
      <div
        className={ classNames(classes.paddingTop, "center", {
          [classes.boxedRelatedSearches]: boxedRelatedSearches,
        }) }
      >
        <AdContent page={ page } />
      </div>
    );
  }

  return (
    <div id="contentMain">
      <div data-testid="parkwebLayout" id="contentLayout">
        <LayoutStyle template={ landerConfig.lander.template } />
        <div
          className={ classNames(classes.domainBanner, {
            [classes.boxShadow]: boxedRelatedSearches,
          }) }
        >
          <TopBanner />
          {domainContent}
        </div>
        <div>{adContent}</div>
        <FooterParkWeb
          copyrightTextId="parkwebcopyrightText"
          parkwebDisclaimerText="parkwebDisclaimerText"
        />
      </div>
    </div>
  );
}

export function DomainInfo({ landerConfig, content }) {
  const text = getParkWebBannerText(landerConfig, content);
  if (typeof text === "undefined" || text === "") {
    return null;
  }
  return <div id={ "domainInfo" }>{text}</div>;
}

export function Domain({ landerConfig }) {
  const domain = (landerConfig.domain || {}).rootDomainDisplayText;
  if (typeof domain === "undefined" || domain === "") {
    return null;
  }
  return <div id={ "domain" }>{domain}</div>;
}

ParkWebLayout.propTypes = {
  page: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

DomainInfo.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  content: PropTypes.object,
};

Domain.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};
