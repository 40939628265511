import ReactHtmlParser from "html-react-parser";
import PropTypes from "prop-types";
import React from "react";
import { Banner } from "../../banner/Banner";
import { QueryParam } from "../../commons/Constants";
import { isMobileBrowser } from "../../commons/HelperFunctions";
import Logger from "../../commons/Logger";
import { TrustArcConsentDialog } from "../../consentManager/TrustArcComponents";
import { useConsentStatus } from "../../consentManager/useConsentStatus";
import { AdContent } from "../AdContent";
import { DomainDisplay } from "../commonComponents/DomainDisplay";
import { FooterCashParking } from "../footer/FooterCashParking";
import { AdSense } from "../utils/adSenseUtils";
import { ArrowUIWrapper } from "./ArrowUIWrapper";
import LayoutStyle, { SimpleLayoutBackground } from "./LayoutStyle";

import classes from "./simple2C.module.scss";
import { useLanderConfig } from "../../commons/ConfigContext";
import { useConsentSerpRedirect } from "../../experimentation/useConsentSerpRedirect";

const SimpleLayout = ({ page, content }) => {
  const { landerConfig, queryConfig } = useLanderConfig();
  const consentStatus = useConsentStatus();
  useConsentSerpRedirect();

  let domain;

  // default is false for this template
  const domainName =
    landerConfig.lander.domainDisplayName || landerConfig.domain.rootDomain;

  if (
    typeof queryConfig[QueryParam.SHOW_DOMAIN] === "boolean"
      ? queryConfig[QueryParam.SHOW_DOMAIN]
      : landerConfig.lander.showDomain
  ) {
    domain = (
      <DomainDisplay
        domainName={ domainName }
        className={ classes.domainDisplay }
      />
    );
  }

  let headerElement;
  if (landerConfig.lander.headerHtml) {
    let decodedHtml = "";
    try {
      decodedHtml = window.atob(landerConfig.lander.headerHtml);
    } catch (e) {
      Logger.error("headerHtml value is not properly encoded" + toString(e));
    }
    headerElement = <HeaderHtmlElement headerHtml={ decodedHtml } />;
  } else if (landerConfig.lander.headerText) {
    headerElement = (
      <HeaderTextElement headerText={ landerConfig.lander.headerText } />
    );
  }

  const renderAdContent = () => {
    if (consentStatus.showConsentDialog) return <TrustArcConsentDialog />;

    let adContainerId = "related_links";
    if (page === AdSense.page.SPONSORED_LISTINGS) {
      adContainerId = "sponsored_listings";
    }
    const ads = (
      <div id={ adContainerId }>
        <AdContent page={ page } />
      </div>
    );
    if (isMobileBrowser()) {
      return ads;
    }
    return (
      <ArrowUIWrapper template={ landerConfig.lander.template }>
        {ads}
      </ArrowUIWrapper>
    );
  };

  return (
    <div id="simpleLayout">
      <div id="contentLayout">
        <SimpleLayoutBackground template={ landerConfig.lander.template } />
        <div>
          <Banner
            landerConfig={ landerConfig }
            queryConfig={ queryConfig }
            content={ content }
          />

          <LayoutStyle template={ landerConfig.lander.template } />

          {headerElement}
          {domain}
        </div>
        <div>{renderAdContent()}</div>
        <FooterCashParking />
      </div>
    </div>
  );
};

SimpleLayout.propTypes = {
  page: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired,
};

export default SimpleLayout;

export function HeaderHtmlElement({ headerHtml }) {
  return <div id="headerElement">{ReactHtmlParser(headerHtml)}</div>;
}

export function HeaderTextElement({ headerText }) {
  return <div id="headerElement">{headerText}</div>;
}

HeaderHtmlElement.propTypes = {
  headerHtml: PropTypes.string.isRequired,
};

HeaderTextElement.propTypes = {
  headerText: PropTypes.string.isRequired,
};
